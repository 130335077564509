import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { defaultSearchOptions, get, getUserItem, save } from "../LocalStore/Item.store.new";
import InitService from "../api/init";
import AuthService from "../api/auth";
// import global_list from "../data/global_keys.json"
// import global_sugg from "../data/global_sugg.json"

// let global_list = [];
let global_list = '';
let global_sugg = [];

// const global_list = [
//   {
//     label: "Google",
//     key: "g",
//     target: "https://google.com/search?q=@@@"
//   },
//   {
//     label: "GXXXoogle",
//     key: "ga",
//     target: "https://gooxgle.com/search?q=@@@"
//   },
//   {
//     label: "DuckDuckGo",
//     key: "d",
//     target: "https://duckduckgo.com/?q=@@@"
//   },
//   {
//     label: "Youtube",
//     key: "yt",
//     target: "https://youtube.com/results?search_query=@@@"
//   }
// ]

const initialState = {
  initialized: false,
  widget_list: [],
  active_widget: {
    meta: null,
    key: null,
    src: null,
    is_live: false,
  },
  active_html: {
    item: null
  },
  active_widget_message: null,
  finder: {
    is_active: false,
    is_focused: false,
    user_query: null,
    parsed_data: {},
    loading: false
  },
  notes_result: {
    active: false,
    data: []
  },
  finder_suggestions: {
    visible: false,
    list: [],
    ai_mode: null,  // 'ask,fix_grammar'
    ai_result: null,
    eval_result: null
  },
  finder_navigation: {
    active_index: 0,
    active: null,
  },
  setting: {
    instant: false,
    add: true,
    suggest: true,
    random: false,
    below: false,
    minimalistic: false,
    improve_text: true,
    ask_ai: true,
    /** chrome only */
    new_tab: true,
    auto_focus: true
  },
  default_search: null,
  items: {
    data: [],
    offset: 0,
    limit: -1,
    count: 0,
    pinned: [],
  },
  search_items: {
    active: false,
    data: [],
    pinned: [],
  },
  items_loading: false,
  current_shortkey: null,
  mobile_view: false,
  activate_mobile_view: false,
  ai_follow_ups: [],
  collection_loading: false,
  active_note: null,
  quick_note: null,
  shortkeys_in_selection: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    return {
      ...state,
      ...action.payload,
      initialized: true,
    };
  },
  SETTING: (state, action) => {
    return {
      ...state,
      setting: action.payload,
    };
  },
  DEFAULT_SEARCH: (state, action) => {
    return {
      ...state,
      default_search: action.payload,
    };
  },
  ITEMS: (state, action) => {
    return {
      ...state,
      items: action.payload,
      items_loading: false,
    };
  },
  FINDER: (state, action) => {
    return {
      ...state,
      finder: action.payload,
    };
  },
  FINDER_NAVIGATION: (state, action) => {
    return {
      ...state,
      finder_navigation: action.payload,
    };
  },
  RESULTS: (state, action) => {
    return {
      ...state,
      finder_suggestions: action.payload,
      finder: {
        ...state.finder,
        loading: false,
      },
    };
  },
  NOTES_RESULT: (state, action) => {
    return {
      ...state,
      notes_result: {
        data: action.payload.result
      }
    }
  },
  SUGG: (state, action) => {
    return {
      ...state,
      finder_suggestions: action.payload
    };
  },
  CURRENT_SHORTKEY: (state, action) => {
    return {
      ...state,
      current_shortkey: action.payload,
    };
  },
  SIDEBAR_SEARCH: (state, action) => {
    console.log("act payload :: ", action.payload);
    return {
      ...state,
      search_items: {
        ...state.search_items,
        ...action.payload,
      },
    };
  },
  ACTIVATE_MOBILE_VIEW: (state, action) => {
    return {
      ...state,
      mobile_view: action.payload.mobile_view || false
    }
  },
  NEW_ACTIVATE_MOBILE_VIEW: (state, action) => {
    return {
      ...state,
      activate_mobile_view: action.payload.activate_mobile_view || false
    }
  },
  AI_FOLLOW_UP: (state, action) => {
    return {
      ...state,
      ai_follow_ups: action.payload.ai_follow_ups || []
    }
  },
  LOAD_WIDGETS: (state, action) => {
    return {
      ...state,
      widget_list: action.payload.widget_list || []
    }
  },
  ACTIVE_WIDGET: (state, action) => {
    return {
      ...state,
      active_widget: action.payload.active_widget || {},
      active_widget_message: JSON.stringify({
        key: state.finder.parsed_data.key,
        data: state.finder.parsed_data.data
      })
    }
  },
  ACTIVE_WIDGET_MESSAGE: (state, action) => {
    return {
      ...state,
      active_widget_message: action.payload.active_widget_message || null
    }
  },
  COLLECTION: (state, action) => {
    return {
      ...state,
      collection_loading: action.payload.collection_loading
    }
  },
  ACTIVE_NOTE: (state, action) => {
    return {
      ...state,
      active_note: action.payload.active_note
    }
  },
  QUICK_NOTE: (state, action) => {
    return {
      ...state,
      quick_note: action.payload.quick_note
    }
  },
  ACTIVE_HTML: (state, action) => {
    return {
      ...state,
      active_html: action.payload.active_html
    }
  },
  SHORTKEYS_IN_SELECTION: (state, action) => {
    return {
      ...state,
      shortkeys_in_selection: action.payload.shortkeys_in_selection
    }
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const SkContext = createContext({
  ...initialState,
  setSetting: (setting_object) => { },
  setDefaultSearch: (default_search) => { },
  setItems: (items_data) => { },
  find: (query) => { },
  setCurrentShortkey: (shortkey) => { },
  createItem: (data) => { },
  sidebarSeach: (query) => { },
  setFinderNavigation: (d) => { },
  launchAction: (item, index) => { },
  setMobileView: (status) => { },
  isMobile: () => { },
  setActivateMobileView: (status) => { },
  setAIConversations: (convo) => { },
  followUpAIConvo: async (newQ) => { },
  loadWidgetsIntoState: (widgets) => { },
  setCollectionLoading: (e) => { },
  setShortkeysInSelection: (e) => { },
  launchQuickNote: () => { }
});

function SkProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const scoredOrderRef = useRef(false);
  const lastNotesKeywordRef = useRef(null);

  useEffect(() => {
    try {
      let l = localStorage.getItem("logs");
      let k = JSON.parse(l || "[]");
      if (k.length > 0) {
        fetch("https://v1.appbackend.io/v1/rows/EjlLSrCObQPJ", {
          method: "POST",
          headers: {
            "Accept": "*/*",
            "User-Agent": "Someones",
            "Content-Type": "application/json",
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(k)
        })
          .then(response => {
            localStorage.removeItem("logs");
            response.json();
          })
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
      }
    } catch (error) {
      console.log("ERROR :: ", error)
    }
  }, []);

  /** Setting */

  const setSetting = (setting_object) => {
    dispatch({
      type: "SETTING",
      payload: setting_object,
    });
  };
  const setDefaultSearch = (default_search) => {
    save("default_search", default_search);
    dispatch({
      type: "DEFAULT_SEARCH",
      payload: default_search,
    });
  };

  async function parseWidgetList(list_json) {
    let widgets_ = [];
    let keys = [];
    let meta = {};
    for (let i = 0; i < list_json.length; i++) {
      const widget_meta = list_json[i];
      keys.push(widget_meta.key);
      meta[widget_meta.key] = {
        title: widget_meta.title,
        view: widget_meta.view,
        ...widget_meta
      }
    }
    widgets_ = [keys, meta];
    loadWidgetsIntoState(widgets_);
  }

  async function loadWidgets() {
    try {
      let widget_list = await fetch("https://shortkey.org/widgets/list.json");
      // let widget_list = await fetch("http://localhost:3000/widgets/list.json");
      if (widget_list.status === 200) {
        let json_ = await widget_list.json();
        await parseWidgetList(json_)
      }
    } catch (error) {

    }
  }

  useEffect(() => {

    loadWidgets();

    async function getAllData() {
      global_list = await InitService.fetchGlobalListData();
      global_sugg = await InitService.fetchGlobalSuggestionData();
    }

    async function getDefault() {
      const defaultSearch = await get("default_search");
      // if(!defaultSearch || defaultSearch === 'undefined') {
      //   setDefaultSearch(getUserItem()[0]);
      // }
      try {
        if (defaultSearch && defaultSearch.title) {
          setDefaultSearch(defaultSearch);
        }
        else {
          // localStorage.setItem(getUserItem[9]);
          // localStorage.setItem("default_search", JSON.stringify({
          //   "_id": "-10",
          //   "title": "Google",
          //   "shortkeys": ["google", "g", "search"],
          //   "target": "https://www.google.com/search?q=@@@",
          //   "pinned": false,
          //   "order": 10
          // }));
          localStorage.setItem("default_search", JSON.stringify({
            "_id": "-10",
            "title": "DuckDuckGo",
            "shortkeys": ["duckduckgo"],
            "target": "https://duckduckgo.com/?q=@@@",
            "pinned": false,
            "order": 10
          }));
          let defaultSearch = await get("default_search");
          if (defaultSearch && defaultSearch.title) {
            setDefaultSearch(defaultSearch);
          }
        }
      } catch (error) {
        localStorage.removeItem("default_search");
        // localStorage.setItem("default_search", JSON.stringify({
        //   "_id": "10",
        //   "title": "Google",
        //   "shortkeys": ["google", "g", "search"],
        //   "target": "https://www.google.com/search?q=@@@",
        //   "pinned": false,
        //   "order": 10
        // }));
        localStorage.setItem("default_search", JSON.stringify({
          "_id": "-10",
          "title": "DuckDuckGo",
          "shortkeys": ["duckduckgo"],
          "target": "https://duckduckgo.com/?q=@@@",
          "pinned": false,
          "order": 10
        }));
        let defaultSearch = await get("default_search");
        if (defaultSearch && defaultSearch.title) {
          setDefaultSearch(defaultSearch);
        }
      }
    }
    getDefault();
    getAllData();
  }, []);

  useEffect(() => {
    // get("default_search").then((ds) => {
    //   if(!ds || Array.isArray(ds)) {
    //     let f = null;
    //     state.items.data.map((d) => {
    //       if(d.title.toLowerCase().trim() === "google") {
    //         f = d;
    //       }
    //     });
    //     setDefaultSearch(f || state.items.data[0]);
    //   }
    // });
  }, [state.items.data]);

  const setShortkeysInSelection = (status) => {
    dispatch({
      type: "SHORTKEYS_IN_SELECTION",
      payload: {
        shortkeys_in_selection: status
      }
    })
  }

  const setItems = (items_data) => {
    let d = items_data["data"] || [];
    let pinned = [];
    d.map((x, i) => {
      if (x.pinned) {
        pinned.push({
          index: i,
          id: x._id,
          item: x,
        });
      }
    });
    dispatch({
      type: "ITEMS",
      // payload: items_data,
      payload: {
        ...items_data,
        data: d,
        pinned: pinned,
      },
    });
    localStorage.setItem("items", JSON.stringify(d));
  };

  const dispatchFinder = (data) => {
    dispatch({
      type: "FINDER",
      payload: data,
    });
    setFinderNavigation({
      active_index: 0,
    });
  };

  const searchInItems = (items, query) => {
    const queryLower = query.toLowerCase();

    // Filter items based on the query
    return items.filter((item) => {
      // Check if the title, shortkeys, or target contains the query string
      const titleMatches = (item.title || "")
        .toLowerCase()
        .includes(queryLower);
      const shortkeysMatches = (item.shortkeys || []).some((shortkey) =>
        shortkey.toLowerCase().includes(queryLower)
      );
      const targetMatches = (item.target || "")
        .toLowerCase()
        .includes(queryLower);

      // Return true if any of the fields match the query
      return titleMatches || shortkeysMatches || targetMatches;
    });
  };

  const sidebarSeach = (query) => {
    if (!query) {
      dispatch({
        type: "SIDEBAR_SEARCH",
        payload: {
          active: false,
        },
      });
      return;
    }
    let dataItems = state.items.data;
    let search_result = searchInItems(dataItems, query);
    let pinned = [];
    search_result.map((x, i) => {
      if (x.pinned) {
        pinned.push({
          index: i,
          id: x.id,
          item: x,
        });
      }
    });
    dispatch({
      type: "SIDEBAR_SEARCH",
      payload: {
        data: search_result,
        pinned: pinned,
        active: true,
      },
    });
  };

  const find = (query, just_return_finder_data = false) => {
    let q = query.trim();
    let splitted = q.split(" ");
    let parsed_data = {
      key: splitted[0],
      data: splitted.slice(1, splitted.length).join(" "),
    };
    let finder_data = {
      is_active: q.length > 0,
      is_focused: true,
      user_query: q,
      parsed_data: parsed_data,
      loading: true,
    };
    // if(just_return_finder_data) {
    //   return finder_data;
    // }

    if (state.finder.parsed_data.key === finder_data.parsed_data.key) {
      if (state.active_widget && state.active_widget.is_live) {
        /** Send message to Widget. */
        dispatch({
          type: "ACTIVE_WIDGET_MESSAGE",
          payload: {
            active_widget_message: JSON.stringify({
              key: parsed_data.key,
              arg: parsed_data.data
            })
          }
        });
        return {
          message_to_widget: parsed_data.data
        };
      }
    }

    if (state.active_note) {
      dispatch({
        type: "ACTIVE_NOTE",
        payload: {
          active_note: null
        }
      });
    }

    if (state.quick_note) {
      dispatch({
        type: "QUICK_NOTE",
        payload: {
          quick_note: null
        }
      });
    }

    if (state.active_html && state.active_html.item) {
      dispatch({
        type: "ACTIVE_HTML",
        payload: {
          active_html: {
            item: null
          }
        }
      });
    }

    if (state.active_widget) {
      dispatch({
        type: "ACTIVE_WIDGET",
        payload: {
          active_widget: {}
        }
      });
      dispatch({
        type: "ACTIVE_WIDGET_MESSAGE",
        payload: {
          active_widget_message: null
        }
      });
    }
    dispatchFinder(finder_data);
    executeSearch(finder_data);
  };

  const matchShortkey = (item, finder_data) => {
    let shortkeys = item.shortkeys || [];
    let qk = finder_data.parsed_data.key.toLowerCase();


    /** GLOBAL */
    // if(qk.startsWith("!")) {
    //   /** */
    //   console.log("GLOBAL KEYS ACTIVATED")
    //   let found_key = null;
    //   for (let i = 0; i < global_list.length; i++) {
    //     const element = global_list[i];
    //     if(element.key === qk.replace("!", "").split(" ")[0]) {
    //       found_key = element;
    //       break;
    //     }
    //   }
    //   if(found_key) {
    //     let words = found_key.target.split(" ");
    //     let url = found_key.target;
    //     words.splice(2, words.length);
    //     console.log("WWWW : ", words)
    //     url = url.replace("@@@", words.join(" "));
    //     window.location.href = url;
    //   }
    // }

    // console.log("S :: ", shortkeys, qk)
    let highest = [-1, -1];
    let response = shortkeys.map((k, i) => {
      let kk = k.toLowerCase();
      if (kk.startsWith(qk)) {
        const commonPrefixLength = Math.min(qk.length, kk.length);
        const matchingValue =
          commonPrefixLength / Math.max(qk.length, kk.length);
        console.log("MA :: ", matchingValue, kk);
        if (matchingValue > highest[1]) {
          highest = [i, matchingValue];
        }
        return [true, matchingValue];
      } else {
        return [false, 0];
      }
    });
    if (highest[0] !== -1 && highest[1] > 0) {
      return [true, highest[1]];
    } else {
      return [false, highest[1]];
    }
  };

  const matchGlobalList = (item, finder_data) => {
    let shortkeys = global_list || [];
    let qk = finder_data.parsed_data.key.toLowerCase().replace("!", "");

    global_list.map((g) => {
      if (g.key.startsWith(qk)) {
        let score = g.key.length - qk.length;
      }
    })

    /** GLOBAL */
    // if(qk.startsWith("!")) {
    //   /** */
    //   console.log("GLOBAL KEYS ACTIVATED")
    //   let found_key = null;
    //   for (let i = 0; i < global_list.length; i++) {
    //     const element = global_list[i];
    //     if(element.key === qk.replace("!", "").split(" ")[0]) {
    //       found_key = element;
    //       break;
    //     }
    //   }
    //   if(found_key) {
    //     let words = found_key.target.split(" ");
    //     let url = found_key.target;
    //     words.splice(2, words.length);
    //     console.log("WWWW : ", words)
    //     url = url.replace("@@@", words.join(" "));
    //     window.location.href = url;
    //   }
    // }

    // console.log("S :: ", shortkeys, qk)
    let highest = [-1, -1];
    let response = shortkeys.map((k, i) => {
      let kk = k.key.toLowerCase();
      if (kk.startsWith(qk)) {
        const commonPrefixLength = Math.min(qk.length, kk.length);
        const matchingValue =
          commonPrefixLength / Math.max(qk.length, kk.length);
        console.log("MA :: ", matchingValue, kk);
        if (matchingValue > highest[1]) {
          highest = [i, matchingValue];
        }
        return [true, matchingValue];
      } else {
        return [false, 0];
      }
    });
    if (highest[0] !== -1 && highest[1] > 0) {
      return [true, highest[1]];
    } else {
      return [false, highest[1]];
    }
  };

  function isCalculation(query) {

    let is_calc = false;
    let is_euro = false;

    // Regex pattern for arithmetic and comparison operators
    const operatorPattern = /[+\-*/%()&|^~<>=]/;
    const numberPattern = /\b\d+(\.\d+)?\b/g;
    // Check if there are at least two numbers in the query
    let numbers = query.match(numberPattern);

    // Ensure the query contains at least one operator and two numbers
    is_calc = operatorPattern.test(query) && numbers && numbers.length > 1 && !query.includes(',');

    if (!query.includes(".") && query.includes(",")) {
      let r = query.replaceAll(",", ".");
      console.log("ICS R : ", r)
      numbers = r.match(numberPattern);
      console.log("ICS NUMBERS : ", numbers)


      // Ensure the query contains at least one operator and two numbers
      is_calc = operatorPattern.test(r) && numbers && numbers.length > 1 && !r.includes(',');

      if (is_calc) {
        is_euro = true;
      }
    }

    return {
      is_calc: is_calc,
      is_eurostyle: is_euro
    }
  }

  const calculateX = (expression) => {
    return new Function('return ' + expression)();
  }

  const executeSearch = async (finder_data) => {
    let search_limit = 6;
    let item_limit = 6;
    let matched_items = [];

    let eval_result = null;

    try {
      let is_c = isCalculation(finder_data.user_query);
      // console.log("ICS :: ", is_c)
      if (is_c.is_calc) {
        eval_result = calculateX(is_c.is_eurostyle ? finder_data.user_query.replaceAll(",", ".") : finder_data.user_query);
        if (is_c.is_eurostyle) {
          eval_result = eval_result.replaceAll(".", ",");
        }
        console.log("\n\n\n\EVEAL RESULT :: ", eval_result)
      }
    } catch (error) {
      console.log("\n\n\n\nERROR :: ", error)
    }

    if (finder_data.user_query && finder_data.user_query.length > 1 && finder_data.user_query.endsWith("?")) {
      if (state.setting['ask_ai']) {
        matched_items.push({
          index: matched_items.length,
          type: "ask"
        })
      }
    }
    else if (finder_data.user_query && finder_data.user_query.length > 1 && finder_data.user_query.endsWith("!")) {

      if (state.setting['improve_text']) {
        matched_items.push({
          index: matched_items.length,
          type: "fix_grammar"
        })
      }
    }

    console.log("FIND :: ", finder_data)
    if (finder_data.user_query.includes(".") && (finder_data.user_query.split(".")[0] || "").length > 0 && (finder_data.user_query.split(".")[1] || "").length > 0 && !finder_data.user_query.trim().includes(" ")) {
      matched_items.push({
        index: matched_items.length,
        type: "open",
      });
    }

    console.log("STATE DEC ::", matched_items)

    if (state.setting.add) {
      item_limit--;
    }

    /** check for pinned items and less there length. */
    // if(state.setting.add) {
    //   final_matched_item_limit--;
    // }

    let pinned_list = [];

    // generate %age for it.
    if (finder_data.parsed_data.key.length > 0) {

      if (finder_data.parsed_data.key.startsWith("/")) {

        let c_matched = []
        let v = finder_data.parsed_data.key.replace("/", '').trim();

        if ("clearall".startsWith(v)) {
          // AuthService.clearShortkeys({});
          matched_items.push({
            type: "cmd:clearall",
            title: "Clear all",
            target: "cmd:clear"
          });
        }
        if (v.length > 0) {
          let colls = await InitService.fetchPlusCollections();

          colls.map((g, i) => {
            if (g.title.toLowerCase().startsWith(v)) {
              c_matched.push({
                ...g,
                score: g.title.length - v.length
              });
            }
          });

          c_matched = c_matched.sort((a, b) => a.score - b.score);

          c_matched.map((gm) => {
            matched_items.push({
              type: "collection",
              title: gm.title,
              target: gm.id
            });
          });
        }

      }
      else {

        if (finder_data.parsed_data.key.startsWith("+") && finder_data.parsed_data.key.length > 1) {
          lastNotesKeywordRef.current = null;
          matched_items.push({
            index: matched_items.length,
            type: "add",
          });
        }
        else if (finder_data.parsed_data.key.startsWith("#")) {
          console.log("NOTE RESULT :: ", finder_data);
          let q = finder_data.parsed_data.key.replace("#", '').trim();
          if (q && q !== lastNotesKeywordRef.current && q.length > 0) {
            lastNotesKeywordRef.current = q;
            let notes_result = await InitService.searchNotes({
              query: q
            });
            console.log("NOTE RESULT :: ", notes_result);

            if (notes_result && notes_result.status === 200) {
              // dispatch({
              //   type: "NOTES_RESULT",
              //   payload: {
              //     active: true,
              //     result: notes_result.data.result.results
              //   }
              // });
              notes_result.data.result.results.map((r) => {
                matched_items.push({
                  type: "note",
                  title: r.Name,
                  target: r.link
                });
              });
            }
            else {
              // dispatch({
              //   type: "NOTES_RESULT",
              //   payload: {
              //     active: true,
              //     result: []
              //   }
              // });
            }
          }
          else {
            lastNotesKeywordRef.current = null;
          }
        }
        else {
          lastNotesKeywordRef.current = null;

          if (!finder_data.parsed_data.key.startsWith("!")) {

            state.items.data.map((item, i) => {
              // if (
              //   (item.shortkeys || []).some((v) =>
              //     v.toLowerCase().startsWith(finder_data.parsed_data.key.toLowerCase())
              //   ) && !item.pinned
              // ) {
              //   if (matched_items.length < item_limit) {
              //     matched_items.push({
              //       ...item,
              //       index: i,
              //     });
              //   }
              // }
              // else
              // {
              //   if(item.pinned) {
              //     pinned_list.push({
              //       ...item,
              //       index: i
              //     })
              //   }
              // }

              const match_result = matchShortkey(item, finder_data);
              if (item.pinned) {
                // pinned_list.push({
                //   ...item,
                //   index: i,
                //   score: match_result[1],
                // });
              } else {
                if (match_result[0]) {
                  matched_items.push({
                    ...item,
                    index: i,
                    score: match_result[1],
                  });
                }
              }
            });

            console.log("STATE DEC2 ::", matched_items, pinned_list)


            let is_scored_order = false;

            pinned_list = pinned_list.map((element, i) => {
              if (
                (element.shortkeys || []).some((v) =>
                  v
                    .toLowerCase()
                    .startsWith(finder_data.parsed_data.key.toLowerCase())
                )
              ) {
                if (!is_scored_order) {
                  is_scored_order = true;
                }
                return {
                  ...element,
                  score: i + 1,
                };
              } else {
                return {
                  ...element,
                  score: (i + 1) * 10,
                };
              }
            });

            if (is_scored_order) {
              scoredOrderRef.current = is_scored_order;
            }

            pinned_list = pinned_list.sort((a, b) => a.score - b.score);
            matched_items = matched_items.sort((a, b) => b.score - a.score);

            if (matched_items.length > item_limit) {
              matched_items = matched_items.splice(0, item_limit);
            }

            // if(state.setting.instant) {
            //   if(matched_items.length === 1 && !(matched_items[0].target || "").includes("@@@")) {
            //     launchAction(matched_items[0], 0, is_scored_order);
            //   }
            // }

            console.log("shortkeys matched items :: ", matched_items, pinned_list);

            matched_items = [...matched_items, ...pinned_list];

            // if (state.setting.random) {
            //   matched_items.push({
            //     index: matched_items.length,
            //     type: "random",
            //   });
            // }
          }

          /** GLOBAL LIST */
          if (finder_data.parsed_data.key.startsWith("!")) {
            let global_matched = []
            let skode = finder_data.parsed_data.key.replace("!", "").toLowerCase();
            if (skode.length > 0) {


              matched_items = searchCSV(global_list, skode);
              // console.log("S :: ", skode, global_matched)

              //   global_list.map((g) => {
              //     // const match_result = matchGlobalList(g, finder_data);
              //     // console.log("X :: ", match_result)
              //     // matched_items.push({
              //     //   type: "sugg",
              //     //   title: "Yotuube"
              //     // });
              //     if (g.key.startsWith(skode)) {
              //       global_matched.push({
              //         ...g,
              //         score: g.key.length - skode.length
              //       });
              //     }
              //   });

              //   global_matched = global_matched.sort((a, b) => a.score - b.score);

              //   global_matched.map((gm) => {
              //     console.log("GM :: ", gm)
              //     matched_items.push({
              //       type: "sugg",
              //       title: gm.label,
              //       target: gm.target
              //     });
              //   })

              //   console.log("GLO :: ", global_matched)
            }
          }
          else {

            let global_matched = []
            let skode = finder_data.user_query.toLowerCase();
            if (skode.length > 0) {


              global_sugg.map((g) => {
                // const match_result = matchGlobalList(g, finder_data);
                // console.log("X :: ", match_result)
                // matched_items.push({
                //   type: "sugg",
                //   title: "Yotuube"
                // });
                if (g.label.toLowerCase().startsWith(skode)) {
                  global_matched.push({
                    ...g,
                    score: g.label.length - skode.length
                  });
                }
              });

              global_matched = global_matched.sort((a, b) => a.score - b.score);

              global_matched.map((gm) => {
                matched_items.push({
                  type: "sugg",
                  title: gm.label,
                  target: gm.target
                });
              })

              console.log("GLO :: ", global_matched)
            }

          }

          if (
            (finder_data.parsed_data.key.startsWith("notes") ||
              finder_data.parsed_data.key.startsWith("note") ||
              finder_data.parsed_data.key.startsWith("no") ||
              finder_data.parsed_data.key.startsWith("n")) && (finder_data.parsed_data.key.length < 6)
          ) {
            matched_items.push({
              type: "quicknote",
              title: "Note",
              target: "quicknote:enable"
            });
          }


          /** PUBLIC WIDGETS */
          if (!finder_data.parsed_data.key.startsWith("!") && state.widget_list && state.widget_list.length > 1) {
            // let sorted_widgets = state.widget_list[0].map((element, i) => {
            //   if (
            //     element.toLowerCase().startsWith(finder_data.parsed_data.key.toLowerCase())
            //   ) {
            //     return {
            //       key: element,
            //       score: i + 1,
            //     };
            //   } else {
            //     return {
            //       key: element,
            //       score: (i + 1) * 10,
            //     };
            //   }
            // });
            let sorted_widgets = [];
            for (let index = 0; index < state.widget_list[0].length; index++) {
              const element = state.widget_list[0][index];
              if (
                element.toLowerCase().startsWith(finder_data.parsed_data.key.toLowerCase())
              ) {
                sorted_widgets.push({
                  key: element,
                  score: index + 1,
                });
              }
            }
            console.log("SORTED WIDGETS :: ", sorted_widgets)
            sorted_widgets = sorted_widgets.sort((a, b) => a.score - b.score);
            let l = 6 - matched_items.length;
            if (l === 0) {
              l = 1;
            }
            sorted_widgets = sorted_widgets.slice(0, l);
            console.log("X :: ", state.widget_list[1], sorted_widgets)
            sorted_widgets.map((wid) => {
              matched_items.push({
                index: matched_items.length,
                type: "widget:public",
                title: state.widget_list[1][wid.key].title,
                key: wid.key
              });
            });
          }

          console.log("STATE DEF :: ", state.default_search, matched_items)

          if (!finder_data.parsed_data.key.startsWith("!")) {
            if (matched_items.length < item_limit) {
              const d = state.default_search;
              if (d) {
                matched_items.push({
                  type: "sugg",
                  title: d.title,
                  target: d.target,
                  is_default: true
                });
              }
            }

            if (state.setting.add) {
              matched_items.push({
                index: matched_items.length,
                type: "add",
              });
            }
          }

        }

      }

      dispatch({
        type: "RESULTS",
        payload: {
          list: matched_items,
          visible: true,
          eval_result: eval_result
        },
      });

      console.log("shortkeys matched items :: ", matched_items);
    } else {
      scoredOrderRef.current = false;
      dispatch({
        type: "RESULTS",
        payload: {
          list: [],
          visible: false,
          eval_result: eval_result
        },
      });
    }
  };

  useEffect(() => {
    // if(state.active_widget) {
    //   console.log("Sending message :: ", state.finder, state.active_widget)
    //   dispatch({
    //     type: "ACTIVE_WIDGET_MESSAGE",
    //     payload: {
    //       active_widget_message: JSON.stringify({
    //         key: state.finder.parsed_data.key,
    //         arg: state.finder.parsed_data.data
    //       })
    //     }
    //   });
    // }
  }, [state.active_widget]);

  const launchQuickNote = (item) => {
    dispatch({
      type: "QUICK_NOTE",
      payload: {
        quick_note: {
          key: item.key,
          val: localStorage.getItem("quicknote") || ""
        }
      }
    });
  }

  const launchWidget = (item) => {
    let w = state.widget_list[1][item.key];
    // let src = "http://localhost:3000/widgets/" + w.view;
    let src = "https://shortkey.org/widgets/" + w.view;
    console.log(`widget :: `, w);
    dispatch({
      type: "ACTIVE_WIDGET",
      payload: {
        active_widget: {
          meta: w,
          key: item.key,
          src: src,
          is_live: w.live || false
        }
      }
    });
  }

  const fetchAndAddCollection = async (item) => {
    let a = await InitService.fetchPlusCollectionValues();
    let i = a[item.target];
    if (i) {
      console.log("Add -> ", i);
      // await authCtx.createItem({
      //   title: i.title,
      //   shortkeys: i.keywords,
      //   target: i.url || i.prompt
      // });
    }
  }

  const launchDucky = (q) => {
    let isHash = q[0] === "#";
    let word = (isHash ? q.replace("#", "%23") : q).split(" ");
    let syntax = `https://duckduckgo.com/?q=!{bang}+{keywords}`;
    // let syntax = `https://duckduckgo.com/?q=!ducky ${word}`;
    syntax = syntax.replace("{bang}", "ducky").replace("{keywords}", () => {
      let a = "";
      word.map((w, i) => {
        // if (i !== 0) {
        a += " " + word[i];
        // }
      });
      return a.trim();
    });
    // console.log("NSY :: ", syntax)
    let u = new URL(syntax);
    // console.log("u :: ", u)
    // window.location.href = u;

    window.location.href = new URL(
      "https://duckduckgo.com/?q=!ducky+amaz+lala&ia=web"
    ); /*.replace(/ /g, "%20");*/
  };

  const launchShortkey = (target, isPinned, is_scored_order, is_default = false) => {
    let q = target.replace(
      "@@@",
      isPinned || is_default
        ? `${state.finder.parsed_data.key} ${state.finder.parsed_data.data ? state.finder.parsed_data.data : ""
          }`.trim()
        : state.finder.parsed_data.data
    );
    // console.log(`q :: `, q, is_default, is_scored_order, state.finder);
    scoredOrderRef.current = false;
    window.location.href = new URL(q);
  };

  const followUpAIConvo = async (newQ) => {
    let current_convo = [{
      role: "user",
      content: state.finder.user_query
    }, {
      role: "assistant",
      content: state.finder_suggestions.ai_result
    }, ...state.ai_follow_ups, {
      role: "user",
      content: newQ
    }];
    let new_convo = [
      ...state.ai_follow_ups,
      {
        role: "user",
        content: newQ
      }
    ];
    const r = await InitService.followUp({
      convo: current_convo
    });
    if (r && r.status === 200) {

      let l = localStorage.getItem("logs");
      let k = JSON.parse(l || "[]");
      k.push({ "type": `${"followup"}`, "query": newQ, "param": "", "result": r.data.answer || "No answer", "url": `${""}`, "time": (new Date()).toUTCString() });
      localStorage.setItem("logs", JSON.stringify(k));

      new_convo.push({
        role: "assistant",
        content: r.data.answer || "No answer"
      });
      setAIConversations(new_convo);
    }
  }

  const clearShortkeys = async () => {
    localStorage.setItem("items", JSON.stringify([]));
    localStorage.removeItem("default_search");
    AuthService.clearShortkeys().then((r) => {
      window.location.reload()
    });
  }

  const launchAction = (
    item,
    index,
    is_scored_order = scoredOrderRef.current
  ) => {

    let result = '';
    let param = '';
    let type = '';

    if (item.type === "open") {
      type = "open";
      console.log("FIND :: ", state.finder.user_query)
      window.location.href = new URL(state.finder.user_query.startsWith("http") ? state.finder.user_query : `http://${state.finder.user_query}`);
    }
    else if (item.type === 'ask') {
      type = "ask";
      dispatch({
        type: "SUGG",
        payload: {
          ...state.finder_suggestions,
          ai_mode: 'ask',
          ai_result: null
        }
      });
      setTimeout(() => {
        InitService.askAI({
          question: state.finder.user_query,
          mode: 'ask'
        }, {}).then((r) => {
          result = r.status && r.status === 200 ? r.data.answer : "Failed";
          dispatch({
            type: "SUGG",
            payload: {
              ...state.finder_suggestions,
              ai_mode: 'ask',
              ai_result: r.status && r.status === 200 ? r.data.answer : "Failed"
            }
          });
        });
      }, 0);
    }
    else if (item.type === 'fix_grammar') {
      type = "fix_grammar";
      dispatch({
        type: "SUGG",
        payload: {
          ...state.finder_suggestions,
          ai_mode: 'fix_grammar',
          ai_result: null
        }
      });
      setTimeout(() => {
        InitService.askAI({
          question: state.finder.user_query.substring(0, state.finder.user_query.length - 1),
          mode: 'optimize'
        }, {}).then((r) => {
          result = "";
          dispatch({
            type: "SUGG",
            payload: {
              ...state.finder_suggestions,
              ai_mode: 'fix_grammar',
              ai_result: r.status && r.status === 200 ? r.data.answer : "Failed"
            }
          });
        });
      }, 0);
    }
    else if (item.type === "random") {
      type = "random";
      /** launch !ducky */
      launchDucky(state.finder.user_query);
    }
    else if (item.type === "widget:public") {
      type = "widget:public";
      launchWidget(item);
    }
    else if (item.type === "quicknote") {
      type = "quicknote";
      launchQuickNote(item);
    }
    else if (item.type === "collection") {
      type = "collection";
      fetchAndAddCollection(item);
    }
    else if (item.type === "cmd:clearall") {
      type = "cmd:clearall";
      console.log("XXXXXXXX")
      clearShortkeys();
    }
    else {

      if (item.target) {
        if (item.target.startsWith("http://") || item.target.startsWith("https://")) {
          /** launch shortkey */
          console.log(
            "Result :: ",
            state.finder_suggestions,
            item,
            is_scored_order
          );
          if (item.target && item.target.length > 0) {
            // console.log("CALLING :: ", item)
            launchShortkey(item.target, is_scored_order ? false : item.pinned, false, item.is_default ? item.is_default : false);
          }
        }
        else if ((item.target || "").startsWith("<note>:")) {
          dispatch({
            type: "ACTIVE_NOTE",
            payload: {
              active_note: item
            }
          });
        }
        else if ((item.target || "").startsWith("<html>:")) {
          console.log("DOING...")
          type = "widget:html";
          dispatch({
            type: "ACTIVE_HTML",
            payload: {
              active_html: {
                item: item
              }
            }
          });
        }
        else {
          type = "ask";
          dispatch({
            type: "SUGG",
            payload: {
              ...state.finder_suggestions,
              ai_mode: 'ask',
              ai_result: null
            }
          });
          setTimeout(() => {
            let q = item.target;
            // if (state.finder.parsed_data.data) {
            //   q += ` "${state.finder.parsed_data.data}"`;
            // }
            InitService.askAI({
              p: item.target,
              question: state.finder.parsed_data.data,
              mode: 'ask'
            }, {}).then((r) => {
              result = r.status && r.status === 200 ? r.data.answer : "Failed";
              dispatch({
                type: "SUGG",
                payload: {
                  ...state.finder_suggestions,
                  ai_mode: 'ask',
                  ai_result: r.status && r.status === 200 ? r.data.answer : "Failed"
                }
              });
            });
          }, 0);
        }
      }
    }

    let l = localStorage.getItem("logs");
    let k = JSON.parse(l || "[]");
    k.push({ "type": `${type || item.type}`, "query": `${state.finder.user_query}`, "param": param, "result": result, "url": `${item.target || item.url}`, "time": (new Date()).toUTCString() });
    localStorage.setItem("logs", JSON.stringify(k));
  };

  const searchCSV = (csvContent, searchKey) => {

    let global_matched = [];


    // Function to calculate scores and store them with line indices
    const getTopScores = (csvContent, searchKey) => {
      const rows = csvContent.split('\n'); // Split into rows based on new lines
      const scores = [];

      rows.forEach((row, index) => {
        const firstComma = row.indexOf(',');
        const secondComma = row.indexOf(',', firstComma + 1);

        if (firstComma !== -1 && secondComma !== -1) {
          const short = row.slice(firstComma + 1, secondComma).trim();

          if (short.toLowerCase().startsWith(searchKey)) {
            const score = short.length - searchKey.length;
            scores.push({ score, index }); // Store score and line index
          }
        }
      });

      // Sort by score in ascending order (best match first)
      return scores.sort((a, b) => a.score - b.score).slice(0, 10); // Get top 10 scores
    };

    // Function to parse specific lines based on the top matches
    const parseTopLines = (csvContent, topMatches) => {
      let r = [];
      const rows = csvContent.split('\n'); // Split into rows again
      topMatches.forEach(({ index }) => {
        const row = rows[index];

        const firstComma = row.indexOf(',');
        const secondComma = row.indexOf(',', firstComma + 1);
        const thirdComma = row.indexOf(',', secondComma + 1);

        if (firstComma !== -1 && secondComma !== -1 && thirdComma !== -1) {
          const title = row.slice(0, firstComma).trim();
          const short = row.slice(firstComma + 1, secondComma).trim();
          const url = row.slice(secondComma + 1, thirdComma).trim();

          r.push({
            type: "sugg",
            // short: short,
            title: title,
            target: url
          });
        }
      });
      return r;
    };


    const topMatches = getTopScores(csvContent, searchKey); // Get top 10 matching lines (scores and indices)
    console.log("T :: ", topMatches)
    global_matched = parseTopLines(csvContent, topMatches); // Parse only the top 10 lines

    // global_matched.forEach((gm) => {
    //   matched_items.push({
    //     type: "sugg",
    //     title: gm.label,
    //     target: gm.target
    //   });
    // });

    // const rows = csvContent.split('\n'); // Split into rows based on new lines

    // rows.forEach((row) => {
    //   // Find the first and second commas (around the 'short' column)
    //   const firstComma = row.indexOf(',');
    //   const secondComma = row.indexOf(',', firstComma + 1);
    //   const thirdComma = row.indexOf(',', secondComma + 1);

    //   if (firstComma !== -1 && secondComma !== -1) {
    //     // Extract the 'short' column value (between second and third commas)
    //     const short = row.slice(firstComma + 1, secondComma).trim();

    //     // Perform the search based on the 'short' column
    //     if (short.toLowerCase().startsWith(searchKey)) {
    //       // Collect the matched row data
    //       const title = row.slice(0, firstComma).trim();
    //       const url = row.slice(secondComma + 1, thirdComma).trim(); // Get URL between second and third commas

    //       global_matched.push({
    //         key: short,
    //         label: title,
    //         target: url,
    //         score: short.length - searchKey.length
    //       });
    //     }
    //   }
    // });

    return global_matched;
  };

  const launchDefaultSearch = (query) => {


    /** GLOBAL */
    if (query.startsWith("!")) {
      /** */
      console.log("GLOBAL KEYS ACTIVATED")
      let found_key = null;
      for (let i = 0; i < global_list.length; i++) {
        const element = global_list[i];
        if (element.key === query.replace("!", "").split(" ")[0]) {
          found_key = element;
          break;
        }
      }
      if (found_key) {
        let words = query.split(" ");
        let url = found_key.target;
        words.splice(0, 1);
        console.log("WWWW : ", words)
        url = url.replace("@@@", words.join(" "));
        window.location.href = url;
        return;
      }
    }

    let q = query.replace(" ", "+");
    let searchUrl = state.default_search.target.replace("@@@", q);
    window.location.href = searchUrl;
  };

  const setCurrentShortkey = (shortkey) => {
    dispatch({
      type: "CURRENT_SHORTKEY",
      payload: shortkey,
    });
  };

  const setFinderNavigation = (findn) => {
    dispatch({
      type: "FINDER_NAVIGATION",
      payload: {
        ...state.finder_navigation,
        ...findn,
      },
    });
  };

  const isMobile = () => {
    return window.innerWidth < 500;
  }

  const setMobileView = (status) => {
    dispatch({
      type: "ACTIVATE_MOBILE_VIEW",
      payload: {
        mobile_view: status
      }
    });
  }

  const setActivateMobileView = (status) => {
    dispatch({
      type: "NEW_ACTIVATE_MOBILE_VIEW",
      payload: {
        activate_mobile_view: status
      }
    });
  }

  const setAIConversations = (convo) => {
    dispatch({
      type: "AI_FOLLOW_UP",
      payload: {
        ai_follow_ups: convo
      }
    });
  }

  const loadWidgetsIntoState = (widgets) => {
    dispatch({
      type: "LOAD_WIDGETS",
      payload: {
        widget_list: widgets
      }
    });
  }

  const setCollectionLoading = (e) => {
    dispatch({
      type: "COLLECTION",
      payload: {
        collection_loading: e
      }
    })
  }

  return (
    <SkContext.Provider
      value={{
        ...state,
        setSetting,
        setDefaultSearch,
        setItems,
        find,
        setCurrentShortkey,
        sidebarSeach,
        setFinderNavigation,
        launchAction,
        launchDefaultSearch,
        setMobileView,
        isMobile,
        setActivateMobileView,
        setAIConversations,
        followUpAIConvo,
        loadWidgetsIntoState,
        setCollectionLoading,
        setShortkeysInSelection,
        launchQuickNote
      }}
    >
      {children}
    </SkContext.Provider>
  );
}

const useSk = () => useContext(SkContext);

export { SkContext, SkProvider, useSk };
