import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthCtx";
import { useSk } from "../../contexts/SkCtx";
import { DialogTabList, useUI } from "../../contexts/UICtx";
import { getApiBase, isChrome } from "../../env";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { ButtonIcon } from "../Buttons";
import CheckboxControl from "../Checkbox";
import Icons from "../Icons";
import PopupMenu, { PopupButton } from "../Popup";
import Select from "../Select/Index";
import { SidebarTabs } from "./constants";
import { isMobile } from 'react-device-detect';
import ToggleSwitchControl from "../ToggleSwitch";
import InitService from "../../api/init";
import AssignSession from "assign-login";
import AuthService from "../../api/auth";

var assignConnectSessionRef = null;

function SidebarHeader({ activeTab, onSearchActiveChange, handleSaveDefaultSearch }) {
  const uiCtx = useUI();
  const skCtx = useSk();
  const authCtx = useAuth();
  const hdrRef = useRef(null);
  const searchBarRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);

  const onSearchKeyUp = (e) => {
    if (e.target.value.trim().length > 0) {
      //   skCtx.setQuery(e.target.value.trim());
      skCtx.sidebarSeach(e.target.value.trim());
      setSearchActive(true);
    } else {
      //   skCtx.setQuery(null);
      skCtx.sidebarSeach(null);
      setSearchActive(false);
    }
  };

  useEffect(() => {
    if (searchActive) {
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  }, [searchActive]);

  const handleClose = () => {
    uiCtx.setSidemenuVisibility(false);
  };
  const handleShorts = () => {
    window.location.hash = "shorts";
  };
  const handleSetting = () => {
    window.location.hash = "setting";
  };

  const onCollect = (e) => {
    e.preventDefault();
    uiCtx.setDialog(true, DialogTabList.AddShort);
  };

  const handleLoginClick = async (e) => {
    let resp = await InitService.getLoginToken({});
    if (resp.status === 200) {
      let j = await resp.data;
      let tokn = j.token;
      window.location.href = `https://bookmarker.me/login?token=${tokn}`;
    }
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    authCtx.handleLogout();
  };

  return (
    <>
      <div ref={hdrRef} className="header sidebarWidth">
        <div className="inner-header">
          <div className="row">
            <ButtonIcon onClick={handleClose}>
              <Icons.CloseIcon />
            </ButtonIcon>
          </div>
          <div className="row">
            <>
              {activeTab === SidebarTabs.Shorts ? (
                <div className="sdSearchbar">
                  <div>
                    <input
                      ref={searchBarRef}
                      onChange={onSearchKeyUp}
                      placeholder="Search"
                    />
                  </div>
                  {searchActive && (
                    <div className="auto" style={{ paddingRight: 10 }}>
                      <ButtonIcon
                        className="btnIconSM"
                        onClick={(e) => {
                          onSearchKeyUp({ target: { value: "" } });
                          searchBarRef.current.value = "";
                        }}
                      >
                        <Icons.CloseIcon />
                      </ButtonIcon>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {/* <div style={{ width: "100%", textAlign: "center" }}>
                    <span>{authCtx.account.email || "Settings"}</span>
                  </div> */}
                  <Select
                    className="text-[16px] h-[46px]"
                    onChange={handleSaveDefaultSearch}
                    height={44}
                    value={skCtx.default_search && skCtx.default_search._id}
                  >
                    {skCtx.default_search._id === "-10" && <option value={"-10"}>DuckDuckGo</option>}
                    {skCtx.items.data
                      .filter((item) => item?.target?.includes("@@@"))
                      .map((opt) => {
                        return (
                          <option key={opt._id} value={`${opt._id}`}>
                            {opt.title}
                          </option>
                        );
                      })}
                  </Select>
                </>
              )}
            </>
            <div className="tabSwitches">
              <ButtonIcon
                onClick={handleSetting}
                className={
                  uiCtx.sidemenu_tab === SidebarTabs.Settings ? "active" : ""
                }
              >
                <Icons.SettingIcon />
              </ButtonIcon>
              <ButtonIcon
                onClick={handleShorts}
                className={
                  uiCtx.sidemenu_tab === SidebarTabs.Shorts ? "active" : ""
                }
              >
                <Icons.MenuIcon />
              </ButtonIcon>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Skey({ item, isLastPinned, handleOnSelect }) {
  const skCtx = useSk();
  const uiCtx = useUI();
  const authCtx = useAuth();

  const selectRef = useRef(null);

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPos, setPopupPos] = useState({
    top: 0,
    right: 0,
  });
  const [deleteActive, setDeleteActive] = useState(false);

  const onPin = (e) => {
    // authCtx.updateItem(item._id, {
    //   pinned: !item.pinned,
    // });

    const searchOption = skCtx.items.data.find((opt) => opt._id === item._id);

    if (searchOption) {
      skCtx.setDefaultSearch(searchOption);
      const newPinned = skCtx.items.data.map((item) => {
        if (item._id === searchOption._id) {
          return { ...item, pinned: true };
        }
        return { ...item, pinned: false };
      });
      skCtx.setItems({
        data: newPinned,
      });
    }
    setPopupVisible(false);
  };

  const onEdit = (e) => {
    uiCtx.setDialog(true, DialogTabList.AddShort);
    skCtx.setCurrentShortkey(item);
  };

  const onDelete = (e) => {
    if (!deleteActive) {
      setDeleteActive(true);
      return;
    }
    authCtx.deleteItem(item._id);
  };

  const onLaunch = (e) => { };

  const onMoveToTop = (e) => {
    authCtx.updateItem(item._id, {
      order: 1,
    });
  };

  const onMoveToBottom = (e) => {
    authCtx.updateItem(item._id, {
      order: skCtx.items.data[skCtx.items.data.length - 1].order,
    });
  };

  const handleOneStepDown = (e) => {
    if (item.order === skCtx.items.data[skCtx.items.data.length - 1].order) {
      return;
    }
    authCtx.updateItem(item._id, {
      order: item.order + 1,
    });
  };

  const handleOneStepUp = (e) => {
    if (item.order === 1) {
      return;
    }
    authCtx.updateItem(item._id, {
      order: item.order - 1,
    });
  };

  const handleChangeMore = (e) => {
    if (e.target.value === "edit") {
      uiCtx.setDialog(true, DialogTabList.AddShort);
      skCtx.setCurrentShortkey(item);
    }
    else if (e.target.value === "delete") {
      authCtx.deleteItem(item._id);
    }
    e.target.value = '';
  }

  const onMore = (e) => {
    if (popupVisible) {
      setPopupVisible(false);
      return;
    }

    /** get height of button to place popup bottom of it */
    const { height } = e.target.getBoundingClientRect();

    setPopupPos({
      top: height + 20,
      right: 0,
    });
    setPopupVisible(true);
  };

  const onPopupClose = (e) => {
    setDeleteActive(false);
  };

  const handleCtxMenu = (e) => {
    e.preventDefault();
    if (popupVisible) {
      setPopupVisible(false);
      return;
    }
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    const y = e.clientY - rect.top; //y position within the element.

    setPopupPos({
      top: y,
      left: x,
    });
    setPopupVisible(true);
  };

  const handleSelectShortkey = (e) => {
    handleOnSelect(e.target.checked);
  }

  return (
    <div className={"shortkey" + (isLastPinned ? " pinned_end" : "")}>
      <div className="inner-shortkey">
        {/* <div className="mainEarly" style={{ width: "auto" }}>
          <ButtonIcon
            onClick={handleOneStepUp}
            style={{ width: 24, height: 24 }}
            disabled={item.order === 1 ? true : false}
          >
            <Icons.AngleUpIcon />
          </ButtonIcon>
          <ButtonIcon
            onClick={handleOneStepDown}
            style={{ width: 24, height: 24 }}
            disabled={item.order === skCtx.items.data.length ? true : false}
          >
            <Icons.AngleDownIcon />
          </ButtonIcon>
        </div> */}
        <div className="main" onClick={onEdit} onContextMenu={handleCtxMenu}>
          <div className="titleAndUrl">
            {/* <span className="title">{item.title + ((item.shortkeys || []).length > 0 ? ` - ${(item.shortkeys || []).join(", ")}` : '')}</span> */}
            <span className="title">
              <span style={{ fontWeight: "normal" }}>{item.title}</span>
              {/* {((item.shortkeys || []).length > 0 ? ` - ${(item.shortkeys || []).join(", ")}` : '')} */}
            </span>
          </div>
          <div className="keywords">
            {(item.shortkeys || []).length > 0
              ? `${(item.shortkeys || []).join(", ")}`
              : ""}
            {/* <span className="url">{item.target}</span> */}
          </div>
        </div>
        <div className="options">
          {/* <ButtonIcon onClick={onMore}>
            <Icons.MoreHorizIcon />
          </ButtonIcon> */}
          {/* <ButtonIcon onClick={(e) => selectRef.current.click()}>
            <Icons.MoreHorizIcon />
          </ButtonIcon>
          <select value={"more"} ref={selectRef} onChange={handleChangeMore} className="hidden-select-sk-more">
            <option value={"more"} disabled>More</option>
            <option value={"edit"}>Edit</option>
            <option value={"delete"}>Delete</option>
          </select> */}
          <label className="checkbox-container">
            <input className="shortkey-checkbox-select-box" onChange={handleSelectShortkey} type="checkbox" defaultChecked={false}></input>
            <span className="checkbox-checkmark"></span>
          </label>
        </div>
      </div>
      {/* <PopupMenu
        visible={popupVisible}
        position={popupPos}
        onClose={onPopupClose}
        style={{ width: 220 }}
      >
        {(item.target || "").includes("@@@") ? (
          <PopupButton
            style={{ paddingRight: 0 }}
            onClick={onPin}
            extraIcon={item.pinned ? <Icons.CheckIcon /> : null}
            text={"Default Search"}
          />
        ) : (
          <></>
        )}
        <PopupButton onClick={onEdit} text={"Edit"} /> */}
      {/* <PopupButton onClick={onLaunch} text={"Launch"} /> */}
      {/* <PopupButton
          onClick={onDelete}
          text={deleteActive ? "Confirm remove?" : "Remove"}
          textClassName={deleteActive ? "delete-warning" : ""}
        />
      </PopupMenu> */}
    </div>
  );
}

function SidebarItemsView({ handleOnSelect }) {
  const skCtx = useSk();

  return (
    <>
      <div className="col shortkeysContainer align-options-in-right">
        <div className="inner-col">
          {/* {(skCtx.search_items.active ? skCtx.search_items.pinned : skCtx.items.pinned).map((pinnedItem, i) => {
            console.log("p :: ", pinnedItem)
            return (
              <Skey key={pinnedItem._id} isLastPinned={i === (skCtx.search_items.active ?skCtx.search_items.pinned :  skCtx.items.pinned).length-1} item={pinnedItem.item} />
            );
          })} */}
          {(skCtx.search_items.active
            ? skCtx.search_items.data
            : skCtx.items.data
          )
            .sort((a, b) => a.title?.localeCompare(b.title))
            .map((item, i) => {
              return <Skey key={item._id} handleOnSelect={handleOnSelect(item._id, i)} item={item} />;
            })}
          {(skCtx.search_items.active
            ? skCtx.search_items.data.length
            : skCtx.items.data.length) < 1 ? (
            <h3>
              {skCtx.search_items.active ? "No results." : "No shortkeys."}
            </h3>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

function SidebarShortsTab() {
  const skCtx = useSk();
  const authCtx = useAuth();
  const searchBarRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);
  const [selectedShortkeys, setSelectedShortkeys] = useState([]);



  const [selectionActivated, setSelectionActivated] = useState(false);

  const onSearchKeyUp = (e) => {
    if (e.target.value.trim().length > 0) {
      //   skCtx.setQuery(e.target.value.trim());
      skCtx.sidebarSeach(e.target.value.trim());
    } else {
      //   skCtx.setQuery(null);
      skCtx.sidebarSeach(null);
    }
  };

  useEffect(() => {
    if (searchActive) {
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  }, [searchActive]);

  const handleOnSelect = (id, i) => (selected) => {
    console.log(`ID :: `, id, selected)
    let d = [...selectedShortkeys];
    if (!selected) {
      d = d.filter(item => item !== id);
      setSelectedShortkeys(d);
    }
    else {
      d.push(id);
      setSelectedShortkeys(d);
    }
  }

  useEffect(() => {
    if (selectedShortkeys.length > 0) {
      setSelectionActivated(true);
    }
    else {
      setSelectionActivated(false);
    }
  }, [selectedShortkeys]);

  useEffect(() => {
    skCtx.setShortkeysInSelection(selectionActivated);
    // if(selectionActivated) {
    //   let f = document.querySelector(".floating-collect-btn");
    //   if(f) {
    //     f.style.display = "none";
    //   }
    // }
    // else
    // {
    //   let f = document.querySelector(".floating-collect-btn");
    //   if(f) {
    //     f.style.display = "flex";
    //   }
    // }
  }, [selectionActivated]);

  const handleOnDelete = async (e) => {
    let q = window.confirm(`Are you sure, you are going to delete ${selectedShortkeys.length} shortkeys.`);
    if(!q) {
      return;
    }
    for (let i = 0; i < selectedShortkeys.length; i++) {
      let refrs = false;
      if (i === selectedShortkeys.length - 1) {
        refrs = true;
      }
      await authCtx.deleteItem(selectedShortkeys[i], refrs);
    }
    setSelectedShortkeys([]);
  }

  return (
    <>
      <div>
        <>
          {!searchActive && (
            <>
              <SidebarHeader
                activeTab={SidebarTabs.Shorts}
                onSearchActiveChange={(v) => setSearchActive(v)}
              />
              <div className="spacer h-30"></div>
              {/* <div className="spacer h-12"></div> */}
            </>
          )}
          <div className="spacer h-30"></div>
          {searchActive && (
            <div className="col sidebarSearchbarContainer">
              <div className="inner-col">
                <div className="sdSearchbar">
                  <div>
                    <input
                      ref={searchBarRef}
                      onChange={onSearchKeyUp}
                      placeholder="Search"
                    />
                  </div>
                  <div className="auto" style={{ paddingRight: 2 }}>
                    <ButtonIcon onClick={(e) => setSearchActive(false)}>
                      <Icons.CloseIcon />
                    </ButtonIcon>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="spacer mb-12"></div>
          <SidebarItemsView handleOnSelect={handleOnSelect} />
          <div className="spacer h-30"></div>
          <div style={{ height: 100 }} className="spacer h-30"></div>
          <div className="spacer h-30"></div>
          <div className="spacer h-30"></div>
        </>
      </div>
      {selectedShortkeys.length > 0 && <div className={("floating-selection") + (isMobile ? " yes-mobile" : "")}>
        <div className="inner">
          <button onClick={(e) => {
            setSelectedShortkeys([]);
            setSelectionActivated(false);
            document.querySelectorAll(".shortkey-checkbox-select-box").forEach((ch) => {
              ch.checked = false;
            })
          }} className="new-buttonx-style" style={{ width: 120 }}>Done</button>
          <button onClick={handleOnDelete} style={{ width: 120 }} className="new-buttonx-style filled">Remove</button>
        </div>
      </div>}
    </>
  );
}

function SidebarSettingsTab() {
  const authCtx = useAuth();
  const skCtx = useSk();
  const searchEngineRef = useRef(null);

  // const assignConnectSessionRef = useRef(null);

  const [filtered, setFiltered] = useState(skCtx.items.pinned);

  useEffect(() => {
    setFiltered(skCtx.items.pinned);
  }, [skCtx.items.pinned]);

  useEffect(() => {
    console.log("DS :: ", skCtx.default_search)
  }, [skCtx.default_search]);

  useEffect(() => {
    if(authCtx.account && authCtx.account.signed) {
      console.log("A ::", authCtx.account)
      AssignSession.createSession({
        // clientId: "e776d841-6a28-4728-aacf-8258ef4987a4",
        clientId: "6a9770de-f03d-4e2b-ba97-0881db1b62b2",
      // clientId: "e776d841-6a28-4728-aacf-8258ef4987a4",
      connectionClient: "f957dfb5-8b82-4eb4-a448-c2c281e8eabd",
        // connectionClient: "c25b0b48-9df6-402e-af16-3fd5ce005645",
        accountId: authCtx.account.account_id
      }, {
        sessionType: "ConnectApp"
      }).then((R) => {
        assignConnectSessionRef = R;
      })
    }
  }, [authCtx.account]);

  const [searchPopupVisible, setSearchPopupVisible] = useState({
    v: false,
    w: 500,
    top: 180,
    right: 50,
  });

  const handleSetting = (setting_key) => (e) => {
    authCtx.updateSetting({
      ...skCtx.setting,
      [setting_key]: e.target.checked,
    });
  };

  const handleRemoveAccount = (e) => {
    e.preventDefault();
    let s = window.confirm("Are you sure?");
    if (s) {
      authCtx.deleteAccount();
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    authCtx.handleLogout();
  }

  const handleSaveDefaultSearch = (e) => {
    const searchOption = skCtx.items.data.find(
      (opt) => opt._id === e.target.value
    );

    if (searchOption) {
      skCtx.setDefaultSearch(searchOption);
      const newPinned = skCtx.items.data.map((item) => {
        if (item._id === searchOption._id) {
          return { ...item, pinned: true };
        }
        return { ...item, pinned: false };
      });
      skCtx.setItems({
        data: newPinned,
      });
    }
  };


  const handleLoginClick = async (e) => {
    let resp = await InitService.getLoginToken({});
    if (resp.status === 200) {
      let j = await resp.data;
      let tokn = j.token;
      window.location.href = `https://bookmarker.me/login?apptoken=${tokn}`;
    }
    else {
      window.alert("Login failed")
    }
  };


  return (
    <>
      <div>
        <SidebarHeader handleSaveDefaultSearch={handleSaveDefaultSearch} activeTab={SidebarTabs.Settings} />
        <div className="sidebar-content">
          <div className="sidebar-body" style={{ overflowY: 'auto' }}>
            <div className="spacer h-30"></div>
            <div className="spacer h-30 mb-12 mt-12"></div>
            {/* <div className="spacer h-30 mb-view-adaption"></div> */}
            {/* <div className="col">
              <div className="inner-col width_full flex flex-col">
                <label className="ml-2 text-[16px] text-[#9B9B9B] font-normal">
                  Default Search
                </label>
                <Select
                  className="text-[16px] h-[48px]"
                  onChange={handleSaveDefaultSearch}
                  value={skCtx.default_search && skCtx.default_search.target}
                >
                  {skCtx.items.data
                    .filter((item) => item?.target?.includes("@@@"))
                    .map((opt) => {
                      return (
                        <option key={opt._id} value={opt.target}>
                          {opt.title}
                        </option>
                      );
                    })}
                </Select>
              </div>
            </div> */}
            {/* <div className="spacer" style={{ marginBottom: 30 }}></div> */}
            <div className="col">
              <div className="inner-col">
                <ToggleSwitchControl
                  onChange={handleSetting("suggest")}
                  label={"Choice options"}
                  jsText={false}
                  desc={`Options based on your input.`}
                  disabled={false}
                  isChecked={skCtx.setting["suggest"]}
                  centerLabel={true}
                />
              </div>
            </div>
            <div className="spacer mb-12"></div>
            <div className="col">
              <div className="inner-col">
                <ToggleSwitchControl
                  onChange={handleSetting("add")}
                  label={"Collect"}
                  jsText={false}
                  desc={`If a Shortkey does not match, you will be able to create a new one.`}
                  disabled={false}
                  isChecked={skCtx.setting["add"]}
                  centerLabel={true}
                />
              </div>
            </div>
            {/* <div className="spacer mb-12"></div>
            <div className="col">
              <div className="inner-col">
                <CheckboxControl
                  onChange={handleSetting("random")}
                  label={"Random"}
                  jsText={false}
                  desc={`You will be able to launch random websites based on search terms if this option is enabled.`}
                  disabled={false}
                  isChecked={skCtx.setting["random"]}
                  centerLabel={true}
                />
              </div>
            </div> */}
            {/* <div className="spacer mb-12"></div>
            <div className="col">
              <div className="inner-col">
                <CheckboxControl
                  onChange={handleSetting("below")}
                  label={"Below"}
                  jsText={false}
                  desc={`If this option is enabled - all new elements will be placed below, otherwise new elements will be placed above.`}
                  disabled={false}
                  isChecked={skCtx.setting["below"]}
                  centerLabel={true}
                />
              </div>
            </div> */}
            <div className="spacer mb-12"></div>
            <div className="col">
              <div className="inner-col">
                <ToggleSwitchControl
                  onChange={handleSetting("minimalistic")}
                  label={"Minimalistic"}
                  jsText={false}
                  desc={`Hide logo and navigation elements.`}
                  disabled={false}
                  isChecked={skCtx.setting["minimalistic"]}
                  centerLabel={true}
                />
              </div>
            </div>
            <div className="spacer mb-12"></div>
            <div className="col">
              <div className="inner-col">
                <ToggleSwitchControl
                  onChange={handleSetting("ask_ai")}
                  label={"Ask AI"}
                  jsText={false}
                  desc={`Question mark at the end, activate AI ask.`}
                  disabled={false}
                  isChecked={skCtx.setting["ask_ai"]}
                  centerLabel={true}
                />
              </div>
            </div>
            <div className="spacer mb-12"></div>
            <div className="col">
              <div className="inner-col">
                <ToggleSwitchControl
                  onChange={handleSetting("improve_text")}
                  label={"Improve text"}
                  jsText={false}
                  desc={`By adding an exclamation mark at the end, it adapts text input.`}
                  disabled={false}
                  isChecked={skCtx.setting["improve_text"]}
                  centerLabel={true}
                />
              </div>
            </div>


            {isChrome() ? (
              <>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <ToggleSwitchControl
                      onChange={handleSetting("new_tab")}
                      label={"Shortkey as new tab"}
                      jsText={false}
                      desc={`Open Shortkey everytime you open a newtab.`}
                      disabled={false}
                      isChecked={skCtx.setting["new_tab"]}
                      centerLabel={true}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <ToggleSwitchControl
                      onChange={handleSetting("auto_focus")}
                      label={"Active input field"}
                      jsText={false}
                      desc={`Write on new tabs directly in the shortkey instead of browser input field.`}
                      disabled={false}
                      isChecked={skCtx.setting["auto_focus"]}
                      centerLabel={true}
                    />
                  </div>
                </div>
              </>
            ) : <></>}
            <div className="spacer -seperator"></div>
            {authCtx.account.signed ? (
              <>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Reset settings and shortkeys"}
                      jsText={false}
                      desc={`It will remove everything and revert to the default settings.`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                      onClick={() => { authCtx.resetApp() }}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Support"}
                      jsText={false}
                      desc={`How can we help you?`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Remove account"}
                      jsText={false}
                      desc={`We will not be able to recover your account once has been deleted.`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                      onClick={() => {
                        let c = window.confirm("Are you sure? We will not be able to recover your account once has been deleted.");
                        if (!c) {
                          return;
                        }
                        authCtx.deleteAccount();
                      }}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Learn how to use"}
                      jsText={false}
                      desc={`See the fist steps on how to use it`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                    />
                  </div>
                </div>
                {/* <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Chnage password"}
                      jsText={false}
                      desc={`You will recieve an email to reset your password.`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                    />
                  </div>
                </div> */}
                {/* <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Change email"}
                      jsText={false}
                      desc={authCtx.account ? authCtx.account.email : ''}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                    />
                  </div>
                </div> */}
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Logout"}
                      jsText={false}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                      desc={authCtx.account ? authCtx.account.email : ''}
                      onClick={() => {
                        let c = window.confirm("Are you sure, it will logout you from shortkey?");
                        if (c) {
                          if (isChrome()) {
                            window.alert("Please logout from website.");
                            window.location.href = 'https://shortkey.org/#setting'
                          }
                          else {
                            authCtx.handleLogout();
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                {authCtx.account && authCtx.account.signed && !authCtx.account.notes_connected && <>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Connect with Notes"}
                      jsText={false}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                      // desc={authCtx.account ? authCtx.account.email : ''}
                      onClick={() => {
                        // let c = window.confirm("Are you sure, it will logout you from shortkey?");
                        // if (c) {
                        //   if (isChrome()) {
                        //     window.alert("Please logout from website.");
                        //     window.location.href = 'https://shortkey.org/#setting'
                        //   }
                        //   else {
                        //     authCtx.handleLogout();
                        //   }
                        // }
                        if(assignConnectSessionRef) {
                          console.log("ASSC :: ", assignConnectSessionRef)
                          assignConnectSessionRef.launchFlow(async (code) => {
                            /** send authcode to your backend, so you can get user info by authcode + clientSecret */
                            if(code) {
                              console.log("TOK :: ", code)
                              await AuthService.update({
                                notesAccessToken: code
                              }, {});
                              authCtx.initialize();
                            }
                            else
                            {
                              window.alert("Failed to connect.");
                            }
                          })
                        }
                        else
                        {
                          window.alert("Please try again.");
                        }
                      }}
                    />
                  </div>
                </div>
                </>}
              </>
            ) : (
              <>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Reset"}
                      jsText={false}
                      desc={`It will remove cookies and revert to the default settings.`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                      onClick={() => { authCtx.resetApp() }}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Support"}
                      jsText={false}
                      desc={`Need help?`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Learn how to use"}
                      jsText={false}
                      desc={`See the fist steps on how to use it`}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                    />
                  </div>
                </div>
                <div className="spacer mb-12"></div>
                <div className="col">
                  <div className="inner-col">
                    <CheckboxControl
                      label={"Login"}
                      jsText={false}
                      disabled={false}
                      centerLabel={true}
                      hideCheckbox={true}
                      // onClick={(e) => window.location.href = `${getApiBase()}auth/login`}
                      onClick={handleLoginClick}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="spacer" style={{ height: 100 }}></div>
          </div>
          {/* <div className="spacer h-30"></div>
        <div className="spacer h-30"></div>
        <div className="spacer h-30"></div>
        <div className="spacer h-30"></div> */}
          {/* <div className="col footer">
            <div className="inner-col">
              {authCtx.account.signed ? (
                <>
                  <a href="#" onClick={handleRemoveAccount}>
                    Remove account
                  </a>
                  <a href="#">Tutorials</a>
                  <a href="#">Support</a>
                  <a href="#">Feedback</a>
                  <a href="#" onClick={handleLogout}>Logout</a>
                </>
              ) : (
                <>
                  <a href="#">About</a>
                  <a href="#">Cookies</a>
                  <a href="#">Privacy</a>
                  <a href="#">Terms</a>
                  <a href="#">Tutorials</a>
                  <a href="#">Support</a>
                </>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

function Sidebar() {
  const sidebarRef = useRef(null);
  const [activate, setActivate] = useState(false);
  const uiCtx = useUI();

  useOnClickOutside(
    sidebarRef,
    (e) => {
      // console.log("E :: ", e.target.tagName);

      // if(e.target.classList.includes("floating-collect-btn")) {

      // }

      let pel = null;

      if (e.target.tagName.toLowerCase() === "path") {
        pel = e.target.parentElement?.parentElement;
        // console.log("PX :: ", e.target.parentElement.parentElement)
      }
      else if (e.target.tagName.toLowerCase() === "svg") {
        pel = e.target.parentElement;
        // console.log("PX :: ", e.target.parentElement)
      }
      else if (e.target.tagName.toLowerCase() === "div") {
        pel = e.target;
        // console.log("PX :: ", e.target)
      }

      if (pel && pel.classList.contains("floating-collect-btn")) {
        // console.log("PX :: ", "OKK")
      }
      else {
        setActivate(false);
        setTimeout(() => {
          uiCtx.setSidemenuVisibility(false);
        }, 200);
      }
    },
    () => { },
    uiCtx.sidemenu_active && !uiCtx.dialog_active
  );

  useEffect(() => {
    if (!activate && uiCtx.sidemenu_active) {
      setTimeout(() => {
        setActivate(true);
      }, 10);
    }
    if (!uiCtx.sidemenu_active) {
      setActivate(false);
      let el = document.querySelector("#shortkey-launcher-input");
      if (el) {
        el.focus();
      }
    }
  }, [uiCtx.sidemenu_active]);

  return (
    <>
      <div
        ref={sidebarRef}
        className={
          "sidebar-wrapper" +
          (uiCtx.sidemenu_active && activate ? " sidebar-active" : " sidebar-active n-sidebar-hidden")
        }
      >
        {uiCtx.sidemenu_tab === SidebarTabs.Shorts ? (
          <SidebarShortsTab />
        ) : uiCtx.sidemenu_tab === SidebarTabs.Settings ? (
          <SidebarSettingsTab />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Sidebar;
